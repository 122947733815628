export default {
  Password: 'Пароль',
  Login: 'Увійти',
  Authentication: 'Аутентифікація',
  Message: 'Повідомлення',
  Messages: 'Повідомлення',
  MessageInvite: 'Прошу підійти до мене',
  Chat: 'Чат',
  type_message: 'Ваше повідомлення',
  addHref: 'Додати посилання на реєстрацію',
  Logout: 'Вийти',
  Fullscreen: 'На повний екран',
  unFullscreen: 'Назад',
  Current_list: 'Поточний список',
  Current_queue: 'Поточна черга',
  Deferred: 'Відкладені',
  Leaf_waiting: 'Лист очікування',
  Leaf_productions: 'Лист запису',
  FIO: 'ПІБ',
  Package_service_record_queue: 'Пакет, послуга, запис, черга',
  Ticket_time: 'Талон, час',
  Phone_email_viber: 'Телефон, email, Viber',
  Action: 'Дія',
  Ticket: 'Талон',
  Automatic: 'Автоматично',
  Options: 'Опції',
  Without_invitation: 'Без запрошення',
  Without_notification: 'Без повідомлень',
  hours: 'г.',
  minutes: 'хв.',
  seconds: 'c.',
  shortDays: 'дн.',
  shortHours: 'г.',
  shortMinutes: 'хв.',
  shortSeconds: 'c.',
  textLost: 'Залишилось:',
  Auto_call: 'Автовиклик',
  Auto_start: 'Автостарт',
  Off: 'Вимк.',
  Console: 'Пульт',
  Install_cancel: 'Скасувати',
  Operator: 'Оператор',
  Set_aside_min: 'Відкласти на, хв',
  Close: 'Закрити',
  Send: 'Надіслати',
  Time: 'Час',
  Direction: 'Напрямок',
  Confirm: 'Підтвердити',
  Time_want: 'Час талона буде:',
  Docstate: 'Послуга: ',
  Equery_num: 'Талон: ',
  Equery_date: 'Дата: ',
  Equery_time: 'Час: ',
  Ok: 'Ок',
  Print: 'Друк',
  Add: 'Додати',
  Nearest: 'На найближчий час',
  Link_damaged: 'Посилання пошкоджено!',
  Operation_successful: 'Ви записалися до черги!',
  Equery_start: 'Час: ',
  Your_name: 'Ваше ім\'я: ',
  Service_name: 'Ваша послуга: ',
  Sunday: 'Неділя',
  Monday: 'Понеділок',
  Tuesday: 'Вівторок',
  Wednesday: 'Середа',
  Thursday: 'Четвер',
  Friday: 'П\'ятниця',
  Saturday: 'Субота',
  Sun: 'Нд',
  Mon: 'Пн',
  Tue: 'Вт',
  Wen: 'Ср',
  Thu: 'Чт',
  Fri: 'Пт',
  Sat: 'Сб',
  Next: 'Наступний',
  Pause: 'Перерва',
  Unpause: 'З паузи',
  Back_work: 'Повернутися до роботи',
  Repeated_call: 'Повторний виклик',
  Start: 'Почати',
  Finish: 'Завершити',
  Move: 'Перенести',
  Remove: 'Видалити',
  Defer: 'Відкласти',
  Call: 'Виклик',
  Del: 'Видалити',
  Put: 'Запис до черги',
  New_client: 'З\'явився новий клієнт!',
  New_client_communication: 'З\'явилася можливість обміну повідомленнями з клієнтом!',
  New_message_client: 'Нове повідомлення від клієнта!',
  Web_console: 'WEB-пульт',
  Plan_the_time: 'Спланувати час',
  Service_time: 'Час обслуговування',
  Free_time: 'Вільний час',
  Accept: 'Прийняти',
  By_appointment: 'За записом',
  Back: 'Назад',
  Graf_works: 'Графік зайнятості',
  buttonChart: 'Завантаженість',
  chartWorkDay: 'Поточний',
  chartWorkAfter: 'Відкладені',
  chartWorkBefore: 'Обслуговувані',
  chartStartDay: 'Початок дня',
  chartCurrent: 'Оброблено',
  chartCurrentTime: 'Поточний час',
  chartEndDay: 'Кінець дня',
  chartBreak: 'Перерва'
}
